import React, { useState } from "react";
import "../StyleSheets/footer.scss";
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GoogleIcon from '@mui/icons-material/Google';

import insta from "../assets/insta.webp";
import LinkedIn from "../assets/linked.webp";
import google from "../assets/google.webp";


const Footer = () => {
  const [instahover, setInstahover] = useState(false);
  const [instahover1, setInstahover1] = useState(false);
  const [instahover2, setInstahover2] = useState(false);
  return (
    <div className="footer_wrapper">
      <footer class="footer">
        <div class="icons">
          <p className="footer-email">
            <a href="mailto:info@solacemanagement.co.uk" className="gmail">info@solacemanagement.co.uk</a>
          </p>
          <a href="#" className="social-media">
            <img src={insta} onMouseOver={e=>setInstahover(true)} onMouseLeave={e=>setInstahover(false)}></img>&nbsp;&nbsp;
            {instahover &&
               <p className="Popup">Please bear with us, We will publish our status soon..</p>
            }
          </a>
          <a href="#" className="social-media">
          <img src={LinkedIn} onMouseOver={e=>setInstahover1(true)} onMouseLeave={e=>setInstahover1(false)}></img>&nbsp;&nbsp;
          {instahover1 &&
               <p className="Popup">Please bear with us, We will publish our status soon..</p>
            }
          </a>
          <a href="#" className="social-media">
          <img src={google} onMouseOver={e=>setInstahover2(true)} onMouseLeave={e=>setInstahover2(false)}></img>
          {instahover2 &&
               <p className="Popup">Please bear with us, We will publish our status soon..</p>
            }
          </a>
          <p class="company-name">&copy; 2023 Stonehenge</p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
