import React from "react";
import house_1 from "../assets/house_one.webp";
import house_2 from "../assets/house_two.webp";
import house_3 from "../assets/house_three.webp";
import house_4 from "../assets/house_four.webp";
import house_5 from "../assets/house_five.webp";
import house_6 from "../assets/house_six.webp";

import "../StyleSheets/cards.scss";

import Footer from "./Footer";

const Cards = () => {
  return (
    <div className="cards-wrapper">
      <div className="row">
        <div className="col-xl-3 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <img class="card-img-top" src={house_1} alt="Card image cap" />
            <div class="card-body1">
              <h5 class="card-title">Stratton Drive, Barking</h5>
              <h4 class="card-text">£600,000 – SOLD</h4>
            </div>
            <div class="card-body2">
              <p class="card-text">
                Stonehenge is delighted to offer to the market this
                immaculately presented three-bedroom 1930's family home. The
                property is located within a quiet tree lined residential road
                set within the much sought after Leftley Estate and is also
                situated within easy reach of Upney Station and other local
                amenities including the Faircross shopping parade and Mayesbrook
                Park.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <img class="card-img-top" src={house_2} alt="Card image cap" />
            <div class="card-body1">
              <h5 class="card-title">Westrow Drive, Barking</h5>
              <h4 class="card-text">£700,000 – SOLD</h4>
            </div>
            <div class="card-body2">
              <p class="card-text">
                Stonehenge is delighted to offer to the market this impressive
                bay fronted four/five-bedroom family home, located within one of
                Barking's premier roads in the ever sought after Leftley Estate.
                The property is offered to the market in immaculate condition
                throughout and comprises of spacious accommodation to the ground
                floor with a bright and airy through lounge with parquet
                flooring and feature fireplace, separate dining room/fifth
                bedroom, kitchen/breakfast room, utility room and WC.
              </p>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <img class="card-img-top" src={house_3} alt="Card image cap" />
            <div class="card-body1">
              <h5 class="card-title">Netherfield Gardens, Barking​</h5>
              <h4 class="card-text">£500,000 – SOLD</h4>
            </div>
            <div class="card-body2">
              <p class="card-text">
                Stonehenge is delighted to offer to the market with no onward
                is this stunning example of an original and unextended
                three-bedroom bay fronted 1920's family home. The property is
                offered to the market in immaculate condition throughout and
                internally comprises of a bay fronted lounge, separate dining
                room and galley kitchen with three well-proportioned bedrooms,
                family bathroom and separate WC to the first floor.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <img class="card-img-top" src={house_4} alt="Card image cap" />
            <div class="card-body1">
              <h5 class="card-title">Netherfield Gardens, Barking</h5>
              <h4 class="card-text">£500,000 – FOR SALE</h4>
            </div>
            <div class="card-body2">
              <p class="card-text">
                Stonehenge is delighted to offer to the market this
                immaculately presented 1930’s three-bedroom family home located
                in a tree lined road within the Faircross Estate. The property
                itself is located under half a mile from Barking mainline
                station which offers direct access to The City of London in just
                14 minutes.
              </p>
            </div>
          </div>
        </div>

        <div className="col-xl-3 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <img class="card-img-top" src={house_5} alt="Card image cap" />
            <div class="card-body1">
              <h5 class="card-title">Netherfield Gardens, Barking</h5>
              <h4 class="card-text">£500,000 – FOR SALE</h4>
            </div>
            <div class="card-body2">
              <p class="card-text">
                Stonehenge is delighted to offer to the market this
                immaculately presented 1930’s three-bedroom family home located
                in a tree lined road within the Faircross Estate. The property
                itself is located under half a mile from Barking mainline
                station which offers direct access to The City of London in just
                14 minutes.
              </p>
            </div>
          </div>
        </div>
        <div className="col-xl-3 col-md-6 col-sm-6 col-xs-12">
          <div class="card">
            <img class="card-img-top" src={house_6} alt="Card image cap" />
            <div class="card-body1">
              <h5 class="card-title">Netherfield Gardens, Barking</h5>
              <h4 class="card-text">£500,000 – FOR SALE</h4>
            </div>
            <div class="card-body2">
              <p class="card-text">
                Stonehenge is delighted to offer to the market this
                immaculately presented 1930’s three-bedroom family home located
                in a tree lined road within the Faircross Estate. The property
                itself is located under half a mile from Barking mainline
                station which offers direct access to The City of London in just
                14 minutes.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div >
  );
};

export default Cards;
