import React from 'react'
import LandingCard from './LandingCard'
import Img from '../assets/Landing.webp'
import Map from '../Components/Map'

export default function GetInTouch() {
  return (
    <div id="getintouch">
      <LandingCard RightSide={`<div class="LoderImg2"></div>`} LeftSide={`<div class="BannerText1"><h2>Get In Touch</h2><p class="sub_content2">6 Mitre Passage, Greenwich</p><p class="sub_content2">London SE10 0ER</p><a class="sub_content2" href="mailto:info@solacemanagement.co.uk">info@solacemanagement.co.uk</a><a class="sub_content2" href="tel:020 3355 5093">020 3355 5093</a></div>`}></LandingCard>
    </div>
  )
}
