import React, { useRef, useState } from "react";
import "../StyleSheets/Map.css";
import { MapContainer, Marker, TileLayer } from "react-leaflet";
import "../StyleSheets/MapLeaflet.css";
import "leaflet/dist/leaflet.css";
import osm from "./Osm-Provider";
import L, { Popup } from "leaflet";

const Map = () => {
  const [center, setCenter] = useState({
    lat: 51.50089933308658,
    lng: 0.0069266274654310065,
  });
  const ZOOM_LEVEL = 12;
  const mapRef = useRef();

  const markerIcon = new L.icon({
    iconUrl: require("../assets/Marker.png"),
    iconSize: [35, 45],
  });
  
  return (
    <div className="MapContainerCss">
      <MapContainer
        center={center}
        zoom={ZOOM_LEVEL}
        ref={mapRef}
        className="ContactMap"
      >
        <TileLayer url={osm.maptiler.url} />

        <Marker
          position={[51.50089933308658, 0.0069266274654310065]}
          icon={markerIcon}
          className="MarkerIconCss"
        ></Marker>
      </MapContainer>
    </div>
  );
};

export default Map;
