import React from 'react'
import '../StyleSheets/LandingCard.scss'

export default function LandingCard(props) {
    setTimeout(() => {
        const div = document.getElementsByClassName("button_Enquire")
        div[0].addEventListener("click", function () {
            window.location.href = "#getintouch"
        })
    }, 500)
    return (
        <div className='Landing_Wrapper'>
            <div className='Landing_Inner_Wrapper'>
                <div className='row'>
                    <div className='col-md-6'>
                        <div dangerouslySetInnerHTML={{ __html: props.LeftSide }}></div>
                    </div>
                    <div className='col-md-6'>
                        <div dangerouslySetInnerHTML={{ __html: props.RightSide }}></div>
                    </div>
                </div>
            </div>
        </div>
    )
}
