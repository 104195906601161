import React, { useEffect, useState } from 'react';
import '../StyleSheets/Navbar.scss';
// import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import ReorderIcon from '@mui/icons-material/Reorder';
import CloseIcon from '@mui/icons-material/Close';


export default function Navbar() {

  const [mobiel, setMobile] = useState(false)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (window.innerWidth <= 500) {
      setMobile(true)
    }
    else {
      setMobile(false)
    }
    setTimeout(()=>{
      if(!window.location.href.split("/").includes("listing")){
        window.location.href="#"+window.location.href.split("#")[1]
      }
    },400)
  }, [])

  const handleChange = (() => {
    if (window.innerWidth <= 500) {
      setMobile(true)
    }
    else {
      setMobile(false)
    }
  })

  window.addEventListener("resize", handleChange)
  return (
    <div className='Navbar_Wrapper'>
      {!mobiel &&
        <div className='Navbar_Inner_Wrapper'>
          <div className='Img_Logo'></div><div className='Nav_Items'><span onClick={e => window.location.href = "/"}>Home</span><span onClick={e => window.location.href = "/listing"}>Listings</span><span onClick={e => {if(window.location.href.split("/").includes("listing")){window.location.href = "/#whychooseas"}else{window.location.href = "#whychooseas"}}}>Why Choose us</span><span onClick={e => {if(window.location.href.split("/").includes("listing")){window.location.href = "/#OurTeam"}else{window.location.href = "#OurTeam"}}}>Our Team</span><span onClick={e => {if(window.location.href.split("/").includes("listing")){window.location.href = "/#Aboutus"}else{window.location.href = "#Aboutus"}}}>About Us</span><span onClick={e => {if(window.location.href.split("/").includes("listing")){window.location.href = "/#getintouch"}else{window.location.href = "#getintouch"}}}>Get In Touch</span></div>
        </div>
      }
      {mobiel &&
        <>
          <div className='Navbar_Inner_Wrapper'>
            <div className='Img_Logo'></div><div className='Nav_Items'><span onClick={e => setOpen(true)}><ReorderIcon /></span></div>
          </div>
          {open &&
            <div className='Navbar_Wrapper_back'>
              <div className='Navbar_Inner_Wrapper1'>
                <div className='Img_Logo'></div><div className='Nav_Items'><span onClick={e => setOpen(false)}><CloseIcon /></span><span onClick={e => { window.location.href = "/"; setOpen(false) }}>Home</span><span onClick={e => { window.location.href = "/listing"; setOpen(false) }}>Listings</span><span onClick={e => { if(window.location.href.split("/").includes("listing")){window.location.href = "/#whychooseas"}else{window.location.href = "#whychooseas"}; setOpen(false) }}>Why Choose us</span><span onClick={e => { if(window.location.href.split("/").includes("listing")){window.location.href = "/#OurTeam"}else{window.location.href = "#OurTeam"}; setOpen(false) }}>Our Team</span><span onClick={e => { if(window.location.href.split("/").includes("listing")){window.location.href = "/#Aboutus"}else{window.location.href = "#Aboutus"}; setOpen(false) }}>About Us</span><span onClick={e => { if(window.location.href.split("/").includes("listing")){window.location.href = "/#getintouch"}else{window.location.href = "#getintouch"}; setOpen(false) }}>Get In Touch</span></div>
              </div>
            </div>
          }
        </>
      }
    </div>
  )
}
