import React from 'react'
import '../StyleSheets/OurTeam.scss'
import pic1 from '../assets/siraj_pic.png'
import pic2 from '../assets/natalie_pic_1.png'
import pic3 from '../assets/pradip_pic_2.png'
import pic4 from '../assets/eugenija_pic_1.png'
import pic5 from '../assets/thameem_pic.png'
import pic6 from '../assets/dawn_pic_2.png'
import pic7 from '../assets/yasid_pic_1.png'
import pic8 from '../assets/edward_pic_1.png'
import pic9 from '../assets/Ejas_Pic.png'
import pic10 from '../assets/oleg_pic_1.png'
import pic11 from '../assets/sergi_pic_1.png'

export default function OurTeam() {
    return (
        <div className='OurTeam_Wrapper' id="OurTeam">
            <h1>Our Team</h1>
            <div className='OurTeam_Inner_Wrapper'>
                <div className='container-xl'>
                    <div className='row'>
                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12'>
                            <div className='card body-img'>
                                <div className='card-body'>
                                    <img src={pic1}></img>
                                </div>
                                <div className='card-footer footer-content'>
                                    <div className='card footer_inside_content'>
                                        <h6>Siraj Deane</h6>
                                        <p>Senior Partner</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 '>
                            <div className='card body-img'>
                                <div className='card-body'>
                                    <img src={pic2}></img>
                                </div>
                                <div className='card-footer footer-content'>
                                    <div className='card footer_inside_content'>
                                        <h6>Natalie Taylor</h6>
                                        <p>Business Development Manager</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 '>
                            <div className='card body-img'>
                                <div className='card-body'>
                                    <img src={pic3}></img>
                                </div>
                                <div className='card-footer footer-content'>
                                    <div className='card footer_inside_content'>
                                        <h6>Pradip Saha</h6>
                                        <p>Senior Property Manager</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 '>
                            <div className='card body-img'>
                                <div className='card-body'>
                                    <img src={pic4}></img>
                                </div>
                                <div className='card-footer footer-content'>
                                    <div className='card footer_inside_content'>
                                        <h6>Eugenija Kovaliova</h6>
                                        <p>Property Manager</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 '>
                            <div className='card body-img'>
                                <div className='card-body'>
                                    <img src={pic5}></img>
                                </div>
                                <div className='card-footer footer-content'>
                                    <div className='card footer_inside_content'>
                                        <h6>Thameem Ansari</h6>
                                        <p>Property Manager</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 '>
                            <div className='card body-img'>
                                <div className='card-body'>
                                    <img src={pic6}></img>
                                </div>
                                <div className='card-footer footer-content'>
                                    <div className='card footer_inside_content'>
                                        <h6>Dawn Harding</h6>
                                        <p>Property Manager</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 '>
                            <div className='card body-img'>
                                <div className='card-body'>
                                    <img style={{ backgroundColor: "#fff" }} src={pic7}></img>
                                </div>
                                <div className='card-footer footer-content'>
                                    <div className='card footer_inside_content'>
                                        <h6>Yazid Yasin</h6>
                                        <p>Property Compliance Manager</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 '>
                            <div className='card body-img'>
                                <div className='card-body'>
                                    <img src={pic8}></img>
                                </div>
                                <div className='card-footer footer-content'>
                                    <div className='card footer_inside_content'>
                                        <h6>Edward Asiedu</h6>
                                        <p>Property Compliance Manager</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 '>
                            <div className='card body-img'>
                                <div className='card-body'>
                                    <img src={pic9}></img>
                                </div>
                                <div className='card-footer footer-content'>
                                    <div className='card footer_inside_content'>
                                        <h6>Ejas Deane</h6>
                                        <p>Sales</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 '>
                            <div className='card body-img'>
                                <div className='card-body'>
                                    <img src={pic10}></img>
                                </div>
                                <div className='card-footer footer-content'>
                                    <div className='card footer_inside_content'>
                                        <h6>Oleg Tsarev</h6>
                                        <p>Maintenance Manager</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-3 col-md-4 col-sm-6 col-xs-12 '>
                            <div className='card body-img'>
                                <div className='card-body'>
                                    <img src={pic11}></img>
                                </div>
                                <div className='card-footer footer-content'>
                                    <div className='card footer_inside_content'>
                                        <h6>Serhill Danyliuk</h6>
                                        <p>Assistant Maintenace Manager</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
