import React from 'react'
import LandingCard from './LandingCard'

export default function Aboutus() {
  return (
    <div id="Aboutus">
      <LandingCard LeftSide={`<div class="LoderImg1"></div>`} RightSide={`<div class="BannerText"><h2>About Us</h2><p class="Self_content">
At the core of our company and our team is the first-class customer service and property advice we deliver. The service we provide and the experience we demonstrate is derived from a combined sixty years plus in the industry we love. We fundamentally believe our first-class customer service is centred around transparency from the fees we charge to the negotiation of your sale and everything in between. We guarantee that all of our properties receive professional photography, videography, and detailed coloured floor-plans as standard, and this teamed with our industry leading marketing techniques and industry wide network ensures that your property will be seen by the widest possible audience.<p><p class="Self_content">The estate agency industry is changing for what we believe is for the better and, with independent business owners becoming far more prominent once again the biggest benefit for our customers is that you are heard, and we are accountable.</p></div>`}></LandingCard>
    </div>
  )
}
