import React from 'react'
import LandingCard from './LandingCard'
import Img from '../assets/Landing.webp'

export default function Banner() {
  return (
    <div>
      <LandingCard RightSide={`<div class="LoderImg"></div>`} LeftSide={`<div class="BannerText"><h1>Stonehenge</h1><p class="sub_content">Real Estate</p><p>
Stonehenge Real Estate is an independent real estate agency based in Barking, East London specialising in residential sales.</p><button class="button_Enquire">Enquire Now</button></div>`}></LandingCard>
    </div>
  )
}
