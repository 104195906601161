import React from 'react'
import '../StyleSheets/Listing.scss'

export default function ListingHeader() {
  return (
    <div className='Listing_Wrapper'>
      <div className='Listing_Inner_Wrapper'>
          <h1>Listings</h1>
      </div>
    </div>
  )
}
