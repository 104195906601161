import React from 'react'
import '../StyleSheets/WhyChoose.scss'
import Trans from '../assets/Trans.webp'
import phone from '../assets/Phne.webp'
import Network from '../assets/Netwrok.webp'
import Market from '../assets/Market.webp'
import LocalKnow from '../assets/LocalKnow.webp'

export default function WhyChoose() {
    return (
        <div className='Header' id="whychooseas">
            <h1>Why Choose Us?</h1>
            <div className='body-container'>
                <div className='InnerCard'>
                    <img src={Trans} width={50} height={50}></img>
                    <p>Transparency</p>
                    <p>Unlike most high street estate agents, we offer a clear and concise fixed fee structure with no hidden costs. Our fees are absolute and under no circumstance we will remove or add a service based on the fee we agree.</p>
                </div>
                <div className='InnerCard'>
                    <img src={phone} width={50} height={50}></img>
                    <p>First class customer service</p>
                    <p>Buying and selling your home should be an event, similar to buying an engagement ring or a dream car. Guidance should be given, knowledge should be shared based on your individual want and need.</p>
                </div>
                <div className='InnerCard'>
                    <img src={LocalKnow} width={50} height={50}></img>
                    <p>Local knowledge</p>
                    <p>Our genuine in-depth local knowledge is key to ensuring we achieve the right result for our clients. We are passionate about the areas we work in and that is why we take a keen interest in the important causes within our community.</p>
                </div>
                <div className='InnerCard'>
                    <img src={Network} width={50} height={50}></img>
                    <p>A network of buyers and sellers</p>
                    <p>We believe in making the right connections. The right connections ensure that we access to the right properties at the right time, and this is why are the agent of choice for buyer and sellers alike.</p>
                </div>
                <div className='InnerCard'>
                    <img src={Market} width={50} height={50}></img>
                    <p>Marketing that gets you noticed</p>
                    <p>Our marketing strategy is simple – maximum exposure that guarantees maximum market value to the best available buyers for you home. We ensure each property that we take to market has professional photography along with a virtual tour and colour floor-plan as standard.</p>
                </div>
            </div>
        </div>
    )
}
