import logo from './logo.svg';
import './App.css';
import Navbar from './Components/Navbar';
import Banner from './Components/Banner';
import WhyChoose from './Components/WhyChoose';
import Aboutus from './Components/Aboutus';
import GetInTouch from './Components/GetInTouch';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Cards from './Components/Cards';
import Footer from './Components/Footer';
import OurTeam from './Components/OurTeam';
import Map from './Components/Map';
import ListingHeader from './Components/ListingHeader';
import { useEffect } from 'react';

function App() {
  return (
    <div className="App">
      {/* <Router>
        <Routes>
          <Route path="" element={<> <Navbar></Navbar>
            <Banner></Banner>
            <WhyChoose></WhyChoose>
            <Aboutus></Aboutus>
            <GetInTouch></GetInTouch></>}>
          </Route>
          <Route path="/ligiting" element={<> <Navbar></Navbar>
            <Cards></Cards></>}>
          </Route>
        </Routes>
      </Router> */}
      <Router>
        <Routes>
          <Route path='/' element={<><Navbar></Navbar><Banner></Banner><WhyChoose></WhyChoose><OurTeam></OurTeam><Aboutus></Aboutus><GetInTouch></GetInTouch><Map></Map><Footer></Footer></>}></Route>
          <Route path='/listing' element={<><Navbar></Navbar><ListingHeader></ListingHeader><Cards></Cards></>}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
